import React, { useEffect, useMemo, useState } from 'react'
import { PrintElement, consts, groupBy } from '../Utlities/functions';
import BModel from '../components/BModel';
import { Formik } from 'formik';
import FormInput from './../components/FormInput';
import * as Yup from "yup"
import FormTextArea from '../components/FormTextArea';
import Header from '../components/Header';
import $ from "jquery"
import JustSelect from './../components/JustSelect';
import LoadingSpinner from '../components/LoadingSpinner';
import FormCheckBox from './../components/FormCheckBox';
import { useRecoilValue } from 'recoil';
import { getText, languageState, pickText } from '../Utlities/app-store';
import { Alert, Hint } from 'fnon';
import FormSelect from '../components/FormSelect';
import FooterInfo from '../components/FooterInfo';
import Report from '../components/Report';
// import { Printd } from 'printd'

function filterTexts(id) {
    const f = consts.filters.find(c => c.Id === id);
    if (f) {
        return pickText(f.Ar, f.En)
    }
    return "";
}

const ItemCard = React.memo(({ index, item, onImageClicked, onRequest, ...etc }) => {
    const { Job, Country, Photo, Religion } = item;
    return (
        <div className={`mt-4 mb-4 col-lg-4 col-md-6`} {...etc}>
            <div className="card border overflow-hidden z-index-2" style={{ height: "450px" }}>
                {/* <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 bg-transparent">
                    <div className={`bg-info shadow-info border-radius-lg py-1 pe-1 text-white px-3 text-center`}>
                        <span className='mx-2'>{filterTexts(Job)}</span>
                    </div>
                </div> */}
                <img src={`/content/${Photo}`} data-source height={440} className="card-img-top hover-effect" style={{ objectFit: "cover", position: "absolute", "top": 0, left: 0, bottom: 0, right: 0 }} onClick={onImageClicked}></img>
                <div className="card-body d-flex flex-column justify-content-end z-index-1 px-0" style={{ pointerEvents: "none" }}>
                    <div className="row bg-white p-1 px-2">
                        <div className="col-12 text-center">
                            {filterTexts(Job)}
                        </div>
                        <hr className="dark horizontal m-0 my-1" />
                        <div className="col-6">
                            <i className="fa-solid fa-earth-americas"></i>
                            <span className="mb-0 text-sm mx-3">{filterTexts(Country)}</span>
                        </div>
                        <div className="col-6">
                            <i className="fa-solid fa-star-and-crescent"></i>
                            <span className="mb-0 text-sm mx-3">{filterTexts(Religion)}</span>
                        </div>
                    </div>
                </div>
                <div className="card-header p-0 position-relative mt-n4 z-index-2 bg-white">
                    <div className={`border-radius-lg  text-white text-center d-flex`}>
                        <a href='https://wa.me/+966135756111' onClick={e => e.stopPropagation()} target="_blank" className='text-white hover-effect scale w-50 d-flex py-1 justify-content-center align-items-center bg-primary'><i className="fa-brands fa-whatsapp fs-4 mr-6"></i> {getText("whatsapp")}</a>
                        <span className='border'></span>
                        <span className={`hover-effect scale w-50 d-flex py-1 justify-content-center align-items-center bg-info`} onClick={onRequest}><i className="fa-regular fa-handshake fs-4 mr-6"></i>{getText("requestnow")}</span>
                    </div>
                </div>
            </div>
        </div>
    )
})

// const validationSchema = Yup.object({
//     Name: Yup.string().required("اكتب اسمك!"),
//     IdentityNumber: requiredIdNumber,
//     Occupation: Yup.string(),
//     WorkPlace: Yup.string(),
//     BankName: Yup.string(),
//     IBANNumber: ibanNumber,
//     PhoneNumber: requiredPhoneNumber,
//     Address: Yup.string(),
//     City: Yup.string(),
//     Email: Yup.string().email("البريد الإلكتروني غير صالح").required("بريدك الإلكتروني لايمكن ان يكون فارغا"),
//     Password: Yup.string().required("الرجاء اختيار رمز سري").min(8, "يجب ان لايكون الرمز السري اقل من 8 احرف او ارقام"),
//     ConfirmPassword: Yup.string().required("لايمكن ان يكون الحقل فارغاً").oneOf([Yup.ref('Password')], "لايتطابق مع رمز المرور!"),
// });

export default function ClientPage() {
    const lng = useRecoilValue(languageState)
    const [order, setOrder] = useState(null);
    const [img, setImg] = useState(null);
    const [filters, setFilters] = useState([]);
    const [people, setPeople] = useState(null);
    const getData = (fresh = false) => {
        if (!fresh && consts.dashboard.length > 0) {
            setPeople(consts.dashboard)
        } else {
            $.ajax("/api/persons/getdata").then(data => {
                consts.dashboard = data;
                setPeople(data);
            }).catch(() => setPeople([]));
        }
        // $.ajax("/api/persons/getdata").then(data => {
        //     consts.dashboard = data;
        //     setPeople(data);
        // }).catch(() => setPeople([]));
    }

    useEffect(() => {
        if (!consts.filters) {
            $.ajax("/api/filters/getdata").then(data => {
                consts.filters = data;
                setFilters(groupBy(data.filter(c => c.Type !== 5 && c.Type !== 6), "Type"));
                getData();
            })
        } else {
            setFilters(groupBy(consts.filters.filter(c => c.Type !== 5 && c.Type !== 6), "Type"));
            getData();
        }
    }, [])
    function handleFilter() {
        //c.Id,c.Country,c.Job,c.Category,c.Experiance,c.Religion
        const fs = $('.row.filter select[data-target]').filter((a, b) => b.value !== "");
        if (fs.length > 0) {
            const _filters = fs.toArray().reduce((ac, i) => {
                if (!ac) ac = {};
                var attr = $(i).attr('data-target');
                attr = attr == 0 ? "Country" : attr == 1 ? "Job" : attr == 2 ? "Category" : attr == 3 ? "Experiance" : "Religion";
                ac[attr] = i.value;
                return ac;
            }, {});
            let data = consts.dashboard;
            Object.keys(_filters).forEach(key => {
                data = data.filter(c => c[key] == _filters[key]);
            })
            setPeople(data);
        } else {
            setPeople(consts.dashboard)
        }
    }
    const filtersHtml = useMemo(() => {
        if (filters.length == 0) return null;
        return (<div className="row rtl filter">
            {filters.map(group => <div className="col-lg-2 col-md-3" key={group.key}><JustSelect title={getText("type" + group.key)} key={group.key} data-target={group.key}>
                <option></option>
                {group.value.map(item => <option value={item.Id} key={item.Id}>{pickText(item.Ar, item.En)}</option>)}
            </JustSelect></div>)}
            <div className='col-12 col-lg-2 col-md-3 text-center'><span className="btn btn-primary" onClick={handleFilter}>{getText("search")}</span></div>
        </div>)
    }, [lng, filters])
    return (
        <>
            <Header>
                <div className="text-center">
                    <span className="btn btn-primary" onClick={() => setOrder({})}>{getText("sendrequestnow")}</span>
                </div>
            </Header>
            <div className="container">
                <div className="container-fluid py-4">
                    {filtersHtml}
                    <div className="row mt-4">
                        {people === null ? <LoadingSpinner title={getText("pleasewait")} /> : people?.length > 0 ? people.map((item, index) => <ItemCard
                            key={item.Id}
                            index={index}
                            item={item}
                            onImageClicked={() => {
                                setImg("/content/" + item.Photo);
                            }}
                            onRequest={() => {
                                setOrder(item)
                            }}
                        />) : null}
                        {/* {data.map((item, index) => <ItemCard key={item.Id} index={index} item={item} onClick={() => setOrder(item)} />)} */}
                    </div>
                </div>
            </div>
            <FooterInfo />
            {order && <Request order={order} setOrder={setOrder} callback={(updated) => {
                if (updated) {
                    handleFilter();
                }
            }} />}
            <ImgPreview img={img} setImg={setImg} />
            <div className="floting-buttons">
                <a href='https://wa.me/+966135756111' onClick={e => e.stopPropagation()} target="_blank" className="hover-effect scale bg-success text-white"><i className="fa-brands fa-whatsapp fs-4"></i></a>
                {/* <a href='tel:00966135756111' onClick={e => e.stopPropagation()} target="_blank" className="hover-effect scale bg-dark text-white"><i className="fa-solid fa-phone fs-6"></i></a> */}
            </div>
        </>
    )
}

const ImgPreview = ({ img, setImg }) => {
    if (!img) return null;
    return (
        <BModel size='xl' show={img} setShow={() => setImg(null)} cancelText={getText('close')}>
            <img src={img} style={{ width: "100%" }} className='hover-effect scale' onClick={e => {
                $(e.target).closest('.modal-dialog').toggleClass('modal-fullscreen')
            }} />
        </BModel>
        // <div className="img-preview bg-gradient-dark"><span className='cls bg-gradient-warning d-flex justify-content-center align-items-center shadow hover-effect scale' onClick={(e) => {
        //     console.log($(e.target).closest('.img-preview'));
        //     $(e.target).closest('.img-preview').slideDown('slaw', () => {

        //         // setImg(null)
        //     });
        // }} style={{ borderRadius: 600, height: 60, width: 60 }}><i className="fa-regular fa-circle-xmark fs-3 text-white"></i></span><img src={img} /></div>
    )
}

const Request = ({ order, setOrder, callback, updatePersons }) => {
    const valCode = Math.floor(Math.random() * 90000) + 10000;
    const lang = useRecoilValue(languageState);
    const [printd, setPrintd] = useState(null);
    const validationSchema = useMemo(() => {
        const rf = getText("requiredf");
        const ff = Yup.string().required(rf).matches(
            /^(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
            getText('invalidphone')
        );
        const reqifstring = Yup.string().when("HasVisa", {
            is: true,
            then: schema => schema.required(rf)
        });
        const reqifnumber = Yup.number().when("HasVisa", {
            is: true,
            then: schema => schema.required(rf).min(1)
        });
        const reqifphone = Yup.string().when("HasVisa", {
            is: true,
            then: schema => schema.required(rf).matches(
                /^(05|5)(5|0|3|6|4|9|1|8|7)([0-9]{7})$/gm,
                getText('invalidphone')
            )
        })
        return Yup.object({
            Name: Yup.string().required(rf),
            IdentityId: Yup.string()
                .required(rf)
                .max(10, getText('identitymax'))
                .matches(/^[0-9]{10}$/gm, getText('invalidvalue')),
            Dob: Yup.string().required(rf),
            Phone: ff,
            valCode: Yup.string(),
            Code: Yup.string().required(rf).oneOf([Yup.ref('valCode')], "Code does not match"),
            HasVisa: Yup.boolean(),
            VisaNumber: reqifstring,
            VisaDate: reqifstring,
            WorkPlace: reqifstring,
            WorkCity: reqifstring,
            RelativeName: reqifstring,
            RelativePhone: reqifphone,
            RelativeWorkPlace: reqifstring,
            RelativeType: reqifstring,
            Address: reqifstring,
            FloorCount: reqifnumber,
            RoomsCount: reqifnumber,
            FamilyCount: reqifnumber,
            BorderNo: Yup.string().when("HasVisa", {
                is: true,
                then: schema => schema.required(rf).max(10)
            })
        })
    }, [lang])
    return (
        <>
            <Formik
                // validateOnBlur={false}
                // validateOnChange={false}
                validationSchema={validationSchema}
                initialValues={{
                    Name: "",
                    IdentityId: "",
                    Dob: "",
                    valCode,
                    HasVisa: false,
                    VisaNumber: "",
                    VisaDate: "",
                    Phone: "",
                    WorkPlace: "",
                    WorkCity: "",
                    RelativeName: "",
                    RelativeType: "",
                    RelativePhone: "",
                    BorderNo: "",
                    RelativeWorkPlace: "",
                    Address: "",
                    FloorCount: 1,
                    RoomsCount: 1,
                    FamilyCount: 1,
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    $.ajax({
                        url: "/api/requests/submit",
                        method: "POST",
                        data: { ...values, PersonId: order.Id }
                    }).then((resp) => {
                        setPrintd(resp);
                        // Hint.Success(getText("sent") + "<br/>" + getText("refrencenumber") + `<b className="mx-2">${resp.id}</b>`)
                        resetForm();
                        consts.dashboard = consts.dashboard.filter(c => c.Id != order.Id);
                        if (callback) callback(true);
                    }).catch((err) => {
                        if (err.status === 303) consts.dashboard = consts.dashboard.filter(c => c.Id != order.Id);
                        Alert.Danger({
                            title: getText('notification'),
                            message: getText(err.status === 303 ? 'personnotavailable' : 'unabletosubmit'),
                            btnOkText: getText('close')
                        })
                    }).always(() => setSubmitting(false))
                    // console.log(values)
                    // setSubmitting(false);
                }}
            >
                {({ handleSubmit, isSubmitting, values }) => (
                    <BModel show={order} setShow={() => setOrder(null)} title={getText("requesttitle")} cancelText={getText("cancel")} okText={getText("send")} theme='info' handleSubmit={handleSubmit} isSubmitting={isSubmitting}>
                        <div className="row rtl">
                            <RequestForm values={values} valCode={valCode} key="client" />
                        </div>
                    </BModel>
                )}
            </Formik>
            {printd && <BModel title={getText('preview')} okText={getText('print')} cancelText={getText('close')} handleSubmit={() => {
                PrintElement(document.querySelector('[data-report-container]'))
            }} show={printd} setShow={(() => {
                setPrintd(null);
                setOrder(null);
            })}>
                <div className="text-center">{getText("sent")}</div>
                <hr className='dark horizontal m-0 my-1' />
                <Report data={printd} setData={() => {
                    setPrintd(null);
                    setOrder(null);
                }} />
            </BModel>}
        </>
    )
}
export const RequestForm = ({ values, valCode, showArchive }) => {
    // useEffect(() => {

    //     if (values.Id > 0) {
    //         // const pr = $.parseHTML(`<button type="button" class="btn btn-success">Print</button><div class="mx-auto"></div>`);
    //         // $('.iam-here').closest('.modal-content').find('.modal-footer').prepend(pr);
    //         // $(pr[0]).click(() => {
    //         //     const item = $('.iam-here').closest('.modal-content').find('.modal-body .row')[0];
    //         //     PrintElement(item);
    //         //     // new Printd().print(item, ["/content/css/material-dashboard.min.css"]);
    //         // })
    //     }

    // }, [])
    return (
        <>
            <div className="float-end"></div>

            {valCode == null && <div className="col-md-6">
                <FormSelect propName="Status" title={getText("requeststatus")}>
                    <option></option>
                    {consts.filters.filter(c => c.Type === 5).map(item => <option key={item.Id} value={item.Id}>{pickText(item.Ar, item.En)}</option>)}
                </FormSelect>
            </div>}
            <div className="col-md-6">
                <FormInput propName="Name" title={getText("name")} required />
            </div>
            <div className="col-md-6">
                <FormInput propName="IdentityId" title={getText("identityid")} required maxLength={10} />
            </div>
            <div className="col-md-6">
                <FormInput propName="Dob" title={getText("dob") + " - " + getText("hjri")} required maxLength={10} />
            </div>
            <div className="col-md-6">
                <FormInput propName="Phone" title={getText("phone")} required maxLength={10} />
            </div>
            <div className="col-md-6">
                <FormCheckBox propName="HasVisa" title={getText("hasvisa")} />
                {showArchive && <FormCheckBox propName="Archived" title={getText('archived')} />}
            </div>
            <hr className="dark horizontal iam-here"></hr>
            {values.HasVisa === true ? <>
                <div className="col-md-6">
                    <FormInput propName="VisaNumber" title={getText("visanumber")} maxLength={100} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="VisaDate" title={getText("visadate") + " - " + getText("hjri")} maxLength={10} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="BorderNo" title={getText("borderno")} maxLength={10} />
                </div>

                <div className="col-md-6">
                    <FormInput propName="WorkPlace" title={getText("workplace")} required maxLength={100} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="WorkCity" title={getText("workcity")} maxLength={100} required />
                </div>
                <div className="col-md-6">
                    <FormInput propName="Address" title={getText("address")} required maxLength={100} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RelativeName" title={getText("reltivename")} required maxLength={150} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RelativeType" title={getText("relativetype")} maxLength={100} />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RelativePhone" maxLength={10} title={getText("reltivephone")} required />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RelativeWorkPlace" title={getText("reltiveworkplace")} required maxLength={100} />
                </div>

                <div className="col-md-6">
                    <FormInput propName="FloorCount" title={getText("floorcount")} required />
                </div>
                <div className="col-md-6">
                    <FormInput propName="RoomsCount" title={getText("roomscount")} required />
                </div>
                <div className="col-md-6">
                    <FormInput propName="FamilyCount" title={getText("familycount")} required />
                </div>
            </> : null}
            {valCode && <>
                <div className="col-md-4 col-sm-8 col-9">
                    <FormInput propName="Code" type="text" title={getText("code")} required maxLength={5} />
                </div>
                <div className="col-md-2 col-sm-4 col-3 mt-2">
                    <span className="input-group-text badge badge-pill bg-gradient-primary px-3 d-flex justify-content-center align-items-center">
                        {valCode}
                    </span>
                </div>
            </>}
            <div className="col-12">
                <FormTextArea propName="Note" title={getText("notes")} maxLength={200} />
            </div>
        </>
    )
}
